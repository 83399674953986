import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="p-10 bg-gray-100">
    <h2 className="text-3xl font-bold mb-4 text-blue-800">{t('contact.title')}</h2>
    <p className="text-gray-700 mb-4">{t('contact.subtitle')}</p>
    <p className="mb-4"><strong>{t('contact.phone')}:</strong> +1 (123) 456-7890</p>
    <p className="mb-4"><strong>{t('contact.email')}:</strong> info@cirkitex.com</p>
    <p><strong>{t('contact.address')}:</strong> 123 Semiconductor Drive, Silicon Valley, CA, 94085</p>
    <p className="text-gray-700 leading-relaxed mt-6">{t('contact.more_info')}</p>
  </section>
  );
};

export default Contact;