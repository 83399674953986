import React from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <section id="services" className="p-10 bg-gray-100">
      <h2 className="text-3xl font-bold mb-4 text-blue-800">{t('services.title')}</h2>
      <ul className="space-y-4 text-gray-700">
        <li><strong>{t('services.custom_ai')}:</strong> {t('services.custom_ai_desc')}</li>
        <li><strong>{t('services.custom_ai_features')}:</strong> {t('services.custom_ai_features_desc')}</li>
        <li><strong>{t('services.ip_solutions')}:</strong> {t('services.ip_solutions_desc')}</li>
        <li><strong>{t('services.full_stack')}:</strong> {t('services.full_stack_desc')}</li>
        <li><strong>{t('services.full_stack_details')}:</strong> {t('services.full_stack_details_desc')}</li>
        <li><strong>{t('services.consulting')}:</strong> {t('services.consulting_desc')}</li>
      </ul>
    </section>
  );
};

export default Services;