import React from 'react';
import { useTranslation } from 'react-i18next';

const Products = () => {
  const { t } = useTranslation();

  return (
    <section id="products" className="p-10 bg-white">
    <h2 className="text-3xl font-bold mb-4 text-blue-800">{t('products.title')}</h2>
    <ul className="space-y-4 text-gray-700">
      <li><strong>{t('products.accel_ai')}:</strong> {t('products.accel_ai_desc')}</li>
      <li><strong>{t('products.accel_ai_use_cases')}:</strong> {t('products.accel_ai_use_cases_desc')}</li>
      <li><strong>{t('products.nextgen_sensors')}:</strong> {t('products.nextgen_sensors_desc')}</li>
      <li><strong>{t('products.nextgen_sensors_advantages')}:</strong> {t('products.nextgen_sensors_advantages_desc')}</li>
      <li><strong>{t('products.neural_accelerators')}:</strong> {t('products.neural_accelerators_desc')}</li>
    </ul>
  </section>
  );
};

export default Products;