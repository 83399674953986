// Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-blue-900 text-white p-6 text-center mt-auto">
      <p>&copy; 2024 {t('company_name')}. {t('footer.message')}</p>
    </footer>
  );
};

export default Footer;