import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';

const Header = ({ onLanguageChange, language }) => {
  const { t } = useTranslation();

  return (
    <header className="bg-blue-900 p-4 text-white flex justify-center items-center shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between w-full max-w-6xl">
        <Link to="/">
        <h1 className="text-2xl font-bold mr-auto">{t('company_name')}</h1>
</Link>
        <nav className="flex-1 text-center">
          <ul className="flex space-x-8 justify-center">
            <li><Link to="/" className="hover:underline">{t('nav.home')}</Link></li>
            <li><Link to="/about" className="hover:underline">{t('nav.about')}</Link></li>
            <li><Link to="/services" className="hover:underline">{t('nav.services')}</Link></li>
            <li><Link to="/products" className="hover:underline">{t('nav.products')}</Link></li>
            <li><Link to="/contact" className="hover:underline">{t('nav.contact')}</Link></li>
            <li><Link to="/team" className="hover:underline">{t('nav.team')}</Link></li>
          </ul>
        </nav>
        <div className="ml-4">
          <Select
            value={language}
            onChange={onLanguageChange}
            variant="outlined"
            className="bg-white text-black rounded p-1 w-20 h-8"
            size="small"
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fi">FI</MenuItem>
          </Select>
        </div>
      </div>
    </header>
  );
};

export default Header;